export default {
    filters: 'Filter',
    filter: 'Filter',
    clearAll: 'Filter zurücksetzen',
    yes: 'Ja',
    no: 'Nein',

    operator: {
        contains: 'enthält',
        equals: 'ist gleich',
        startsWith: 'beginnt mit',
        endsWith: 'endet mit',
        greaterThan: 'ist größer als',
        lessThan: 'ist kleiner als',
        greaterThanOrEqual: 'ist größer oder gleich',
        lessThanOrEqual: 'ist kleiner oder gleich',
        notEquals: 'ist nicht gleich'
    }
}