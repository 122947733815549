export default {
    home: 'Home',
    dashboard: 'Dashboard',
    users: 'Nutzer',
    organisations: 'Lieferanten',
    settings: 'Einstellungen',
    myProfile: 'Mein Profil',
    information: 'Informationen',
    secret: 'Geheimnis',
    nothingToSeeHere: 'Hier gibt es nichts zu sehen 🙈',
    escapeRoom: '//farbcode Dungeon Escape! 🏰',
    logout: 'Logout',
    baseData: 'Stammdaten',
    categories: 'Kategorien',
    loginProviders: 'Login Methoden',
    orders: 'Beschaffungen',
    devices: 'Standard Geräte',
    costCenters: 'Kostenstellen',
    mailTemplates: 'Mail Vorlagen',
}
