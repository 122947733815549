export default {
    filters: 'Filters',
    filter: 'Filter',
    clearAll: 'Clear all',
    yes: 'Ja',
    no: 'Nein',

    operator: {
        contains: 'contains',
        equals: 'is equal to',
        startsWith: 'starts with',
        endsWith: 'ends with',
        greaterThan: 'is greater than',
        lessThan: 'is less than',
        greaterThanOrEqual: 'is greater or equal',
        lessThanOrEqual: 'is less or equal',
        notEquals: 'is not equal to'
    }
}