export default {
    title: 'Ergebnis',
    actionsExecuted: 'Es wurde kein Ereignis festgehalten|Es wurde ein Ereignis festgehalten|Es wurden {count} Ereignisse festgehalten',

    type_default_action: "Keine Aktion ausgeführt|Eine Aktion ausgeführt|{count} Aktionen ausgeführt",
    type_user_deleted: "Kein Nutzer gelöscht|Ein Nutzer gelöscht|{count} Nutzer gelöscht",
    type_user_not_deletable: "Kein Nutzer konnte nicht gelöscht werden|Ein Nutzer konnte nicht gelöscht werden|{count} Nutzer konnten nicht gelöscht werden",
    type_device_deleted: "Kein Gerät gelöscht|Ein Gerät gelöscht|{count} Geräte gelöscht",
    type_device_not_deletable: "Kein Gerät konnte nicht gelöscht werden|Ein Gerät konnte nicht gelöscht werden|{count} Geräte konnten nicht gelöscht werden",
    type_cost_center_deleted: "Keine Kostenstelle gelöscht|Eine Kostenstelle gelöscht|{count} Kostenstellen gelöscht",
    type_cost_center_not_deletable: "Keine Kostenstelle konnte nicht gelöscht werden|Eine Kostenstelle konnte nicht gelöscht werden|{count} Kostenstellen konnten nicht gelöscht werden",
    type_cost_center_activated: "Keine Kostenstelle aktiviert|Eine Kostenstelle aktiviert|{count} Kostenstellen aktiviert",
    type_cost_center_deactivated: "Keine Kostenstelle deaktiviert|Eine Kostenstelle deaktiviert|{count} Kostenstellen deaktiviert",
    type_cost_center_not_activatable: "Keine Kostenstelle konnte nicht aktiviert werden|Eine Kostenstelle konnte nicht aktiviert werden|{count} Kostenstellen konnten nicht aktiviert werden",
    type_cost_center_not_deactivatable: "Keine Kostenstelle konnte nicht deaktiviert werden|Eine Kostenstelle konnte nicht deaktiviert werden|{count} Kostenstellen konnten nicht deaktiviert werden",
    type_device_activated: "Kein Gerät aktiviert|Ein Gerät aktiviert|{count} Geräte aktiviert",
    type_device_deactivated: "Kein Gerät deaktiviert|Ein Gerät deaktiviert|{count} Geräte deaktiviert",
    type_device_not_activatable: "Kein Gerät konnte nicht aktiviert werden|Ein Gerät konnte nicht aktiviert werden|{count} Geräte konnten nicht aktiviert werden",
    type_device_not_deactivatable: "Kein Gerät konnte nicht deaktiviert werden|Ein Gerät konnte nicht deaktiviert werden|{count} Geräte konnten nicht deaktiviert werden",
}
