import tools from './tools/main'
import filter from './filter/main'

export default {

    overview: 'Overview',
    totalEntries: 'No Results |  One result found | A total of {count} results found',
    labelPerPageOptions: 'Entries per page',

    actions: {
        filters: 'Filter',
        create: 'Create',
        edit: 'Edit',
        tools: 'Tools'
    },

    tools,
    filter
}