import table from './table'

export default {
    provider: 'Anbieter',
    client_id: 'Client ID',
    client_secret: 'Client Secret',
    redirect: 'URL',
    tenant_id: 'Tenant ID',

    providerId: 'Login Methode {id}',

    createProvider: 'Anbieter hinzufügen',
    deleteProvider: 'Anbieter löschen',
    deleteProviderInfo:
        'Möchtest du den Anbieter wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    noOrganisationFound: 'Es konnte kein Lieferant gefunden werden.',
    providerAlreadyExists: 'Es existiert bereits eine Login Method zu diesem Anbieter.',
    providerNotDeletable: 'Dieser Anbieter kann nicht gelöscht werden.',
    deleteProviderSuccess: 'Der Anbieter wurde erfolgreich gelöscht.',
    redirect_url: 'Umleitungs-URI',

    providers: {
        google: 'Google',
        azure: 'Microsoft'
    },

    table
}