import table from './table'

export default {
    baseData: 'Grunddaten',
    name: 'Name',
    description: 'Beschreibung',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    table
}