import info from './info'
import menu from './menu'
import table from './table'

export default {
    organisationId: 'Lieferant {id}',

    name: 'Name',
    email: 'E-Mail',
    membersCount: 'Mitglieder',

    deleteOrganisation: 'Lieferant löschen',
    deleteOrganisationText:
        'Möchtest du die Organisation wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrganisationSuccess: 'Lieferant wurde erfolgreich gelöscht.',
    deleteOrganisationError: 'Der Lieferant konnte nicht gelöscht werden.',

    toggleState: 'aktivieren / deaktivieren',
    toggleStateSuccess: 'Status wurde erfolgreich geändert.',
    toggleStateError: 'Der Status konnte nicht geändert werden.',

    info,
    menu,
    table
}