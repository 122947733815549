export default {
    title: 'Lieferanten',
    name: 'Name',
    email: 'E-Mail',
    created_at: 'Erstellt am',
    active: 'Status',
    isActive: 'Aktiv',
    isInactive: 'Inaktiv',
    id: 'ID'
}
