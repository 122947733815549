export default {
    title: 'Standard Gerät erstellen',
    name: 'Bezeichnung',
    image: 'Bild',
    description: 'Beschreibung',
    price: 'Preis',
    priceType: 'Preisart',
    net: 'Netto',
    gross: 'Brutto',
    category: 'Kategorie',
    offer: 'Angebot',
    offerDocument: 'Angebotsdokument',
    supplier: 'Lieferant',
    details: 'Details',
    application_area: 'Anwendungsbereich',
    createDevice: 'Standard Gerät erstellen',
    createDeviceSuccess: 'Das Standard Gerät wurde erfolgreich erstellt.',
    createDeviceError: 'Das Standard Gerät konnte nicht erstellt werden.',
    createOfferSuccess: 'Das zugehörige Angebot wurde erfolgreich erstellt.',
    createOfferError: 'Das zugehörige Angebot konnte nicht erstellt werden.',
}