import info from './info'
import menu from './menu'
import table from './table'

export default {
    title: 'Users',
    userId: 'User {id}',
    newUser: 'New User',
    createUser: 'Create new user',

    deleteUser: 'Delete account',
    deleteOtherUserText: 'Do you really want to delete the account of {name}? This action can not be undone.',
    deleteOwnUserText: 'Do you really want to delete your account? This action can not be undone.',

    updateSuccess: 'User was successfully saved',
    welcome: 'Welcome {name}!',

    firstname: 'Firstname',
    lastname: 'Lastname',
    email: 'E-Mail',
    profileImage: 'Profile Image',
    profile_image_id: 'Profile Image ID',
    password: 'Password',
    passwordRepeat: 'Repeat password',


    roles: {
        user: 'Employee',
        maintenance: 'Stammdaten Verwaltung',
        supplier: 'Ansprechperson',
        admin: 'Administrator'
    },

    info,
    menu,
    table
}