import table from './table'
export default {
    baseData: 'Grunddaten',
    name: 'Bezeichnung',
    image: 'Bild',
    description: 'Beschreibung',
    price: 'Preis',
    priceTypes: {
        net: 'Netto',
        gross: 'Brutto',
    },
    category: 'Kategorie',
    offer: 'Angebot',
    supplier: 'Lieferant',
    details: 'Details',
    application_area: 'Anwendungsbereich',
    offerDocument: 'Angebotsdokument',

    toggleStatus: 'Status umschalten',
    toggleStatusSuccess: 'Status wurde erfolgreich umgeschaltet.',
    toggleStatusError: 'Der Status konnte nicht umgeschaltet werden.',

    deleteDevice: 'Gerät löschen',
    deleteDeviceText:
        'Möchtest du das Gerät wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteDeviceSuccess: 'Gerät wurde erfolgreich gelöscht.',
    deleteDeviceError: 'Das Gerät konnte nicht gelöscht werden.',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    searchCategory: 'Kategorie suchen',
    searchSupplier: 'Lieferant suchen',

    createOfferSuccess: 'Das zugehörige Angebot wurde erfolgreich erstellt.',
    createOfferError: 'Das zugehörige Angebot konnte nicht erstellt werden.',

    table,
}