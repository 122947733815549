export default {
    subject: 'Betreff',
    subjectPlaceholder: 'Betreff...',
    body: 'Inhalt',
    bodyPlaceholder: 'Inhalt der E-Mail...',
    bodyInfo: 'Um Funktionen von Blade Mail-Komponenten (Buttons, Tabellen etc.) zu benutzen, verwende den Code-Block Button (&lt/&gt) und schreibe darin deinen Inhalt. (<a href=\"https://laravel.com/docs/11.x/notifications#writing-the-message\" target=\"_blank\">Infos hier</a>). Du kannst dieselben Variablen wie im Text benutzen, nur in der <a href="https://laravel.com/docs/11.x/blade#displaying-data">Blade-Syntax</a>.<br/> Beispiel: /appName im Text entspricht  {\'{{ $appName }}\'} im Code-Block.',
    updateSuccess: 'Die E-Mail-Vorlage wurde erfolgreich aktualisiert.',
    de: 'Deutsch',
    en: 'Englisch',
    template: 'Vorlage',
    emailSettings: 'Mail-Einstellungen',
    bcc: "Kopie-Adresse(n) (BCC)",
    bccDescription: "E-Mail-Adressen, die eine Kopie der E-Mail erhalten sollen. Mehrere Adressen können durch \";\" getrennt werden.",
    replyToEmail: "Antwort-Adresse (Reply-To)",
    replyToName: "Antwort-Name (Reply-To)",
    replyToEmailDescription: "E-Mail-Adresse, die als Antwort-Adresse für die E-Mail verwendet werden soll.",
    replyToNameDescription: "Name, der für die Antwort-Adresse der E-Mail verwendet werden soll.",
    test: "Testen",
    updateAndSendTestEmail: "Speichern und Test-E-Mail an \"{email}\" senden.",
    testEmailSent: "Test-E-Mail wurde erfolgreich an \"{email}\" gesendet.",
    table: {
        name: "Name",
        created_at: "Erstellt am",
        updated_at: "Aktualisiert am",
    }
}