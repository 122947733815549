export default {
    intro: "Intro",
    introText: "Do not take this game to seriously, i have many more ideas for rooms and puzzles. But it should be enough to use it for ui testing 😅",
    playerName: "Player Name",
    finish: "End of the game!",
    winMessage: "You won!",
    loseMessage: "This Dungeon was too much for you! You lost!",
    stats: "Stats",

    stories: {
        dungeon1: 'You find yourself in a dark room. You can only see a little, but it seems like you are trapped in a dungeon. You have to find a way to escape!',
        dungeon2: 'A secret passage led you into a basement. It looks like the old vault of a great castle. You have to find a way to escape!',
        bossBattle: 'This is the last room. You are facing the boss. You have to defeat him to escape!',
    }
}