export default {
    title: 'Nutzer erstellen',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createUser: 'Nutzer erstellen',
    createAndEditUser: 'Erstellen und weiter bearbeiten',
    createUserSuccess: 'Der Nutzer wurde erfolgreich erstellt.',
    createUserError: 'Der Nutzer konnte nicht erstellt werden.'
}