
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/de'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(quarterOfYear)
dayjs.extend(updateLocale)
dayjs.extend(updateLocale)
dayjs.locale('en')
dayjs.locale('de')
dayjs.tz.setDefault('Central European Time')


dayjs.updateLocale("de")

export default dayjs
