import { default as indexh0NT31gGQ3Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/auth/index.vue?macro=true";
import { default as indexp1V3HJ5GzgMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_93w1xMjtpkanMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/categories/[id].vue?macro=true";
import { default as index6qU4woGGUxMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/categories/index.vue?macro=true";
import { default as _91id_93KywEsZIQYbMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/costCenters/[id].vue?macro=true";
import { default as indexfUBzc7MO61Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/costCenters/index.vue?macro=true";
import { default as _91id_93CTNSalOq16Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/devices/[id].vue?macro=true";
import { default as indexpX2sFRKqM1Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/devices/index.vue?macro=true";
import { default as indexnUjo9wHlqzMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/mailTemplates/index.vue?macro=true";
import { default as indexu0zbHqFKBeMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/index.vue?macro=true";
import { default as indexNmg1EUBRm4Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/orders/index.vue?macro=true";
import { default as ordersApTbE6Y8P5Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/orders.vue?macro=true";
import { default as organisationBaseData8LPwklttf4Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93vupAFxA29jMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations/[id].vue?macro=true";
import { default as indexYCbNKqrJkrMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations/index.vue?macro=true";
import { default as organisationslxatISMCoKMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations.vue?macro=true";
import { default as secrett67J2MWPV5Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/secret.vue?macro=true";
import { default as _91id_93rzOM9ZHwwTMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexdfGTVvn1JKMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93YcB8CuUU0GMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexyiWizxq2RGMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsnk6hTwnA45Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings.vue?macro=true";
import { default as userActivitiesQiKnd1CfTDMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseData9CQ5Yt2Le5Meta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsxcbmGxuKEsMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93t2ULPdyFYWMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id].vue?macro=true";
import { default as indexEeyenHqqByMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/index.vue?macro=true";
import { default as usersFYC5Zk8ijmMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93rVfMHJgb8QMeta } from "/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexh0NT31gGQ3Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexp1V3HJ5GzgMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories-id",
    path: "/baseData/categories/:id()",
    meta: _91id_93w1xMjtpkanMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories",
    path: "/baseData/categories",
    meta: index6qU4woGGUxMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters-id",
    path: "/baseData/costCenters/:id()",
    meta: _91id_93KywEsZIQYbMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/costCenters/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters",
    path: "/baseData/costCenters",
    meta: indexfUBzc7MO61Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/costCenters/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-devices-id",
    path: "/baseData/devices/:id()",
    meta: _91id_93CTNSalOq16Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-devices",
    path: "/baseData/devices",
    meta: indexpX2sFRKqM1Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-mailTemplates",
    path: "/baseData/mailTemplates",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/baseData/mailTemplates/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: ordersApTbE6Y8P5Meta?.name,
    path: "/orders",
    meta: ordersApTbE6Y8P5Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "orders",
    path: "",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: organisationslxatISMCoKMeta?.name,
    path: "/organisations",
    meta: organisationslxatISMCoKMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93vupAFxA29jMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexYCbNKqrJkrMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/secret.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93rzOM9ZHwwTMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexdfGTVvn1JKMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93YcB8CuUU0GMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexyiWizxq2RGMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersFYC5Zk8ijmMeta?.name,
    path: "/users",
    meta: usersFYC5Zk8ijmMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93t2ULPdyFYWMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseData9CQ5Yt2Le5Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexEeyenHqqByMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93rVfMHJgb8QMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20240918083500/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]